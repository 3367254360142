<template>
  <div class="page menu background">
    <div class="content fg-white">
      <h1 class="font-stylized font-size-105 line-height-10 mb-16">CLARA’S STORY</h1>
      <p class="font-size-26 line-height-15 mb-7">Select an image on the right to begin Clara’s story.</p>
      <p class="font-size-26 line-height-15 mb-7">You can access any part of Clara’s story, however to <br>earn CPD accreditation and obtain your certificate, <br>you’ll need to complete all 4-parts of the <br>educational experience in order.</p>
      <p class="font-black font-size-26 line-height-15 text-underline">CPD accreditation will not be achieved by <br>completing only part of the experience.</p>
    </div>
    <ul class="menu-items">
      <li
        v-for="item in items"
        :key="item.index"
        :class="[
          { completed: phases[item.index - 1].pass },
        ]"
        @click="onClick(item.action)"
      >
        <div class="heading font-extrabold">
          <div class="index font-size-30 line-height-10" v-html="item.index"></div>
          <p class="font-size-26 line-height-10" v-html="item.heading"></p>
        </div>
        <div class="thumbnail">
          <div class="image-wrapper">
            <img :src="`static/images/phase${item.index}.jpg`" alt="...">
          </div>
        </div>
        <p class="text font-size-22 line-height-12" v-html="item.text"></p>
        <p class="duration font-size-13 line-height-10 text-uppercase" v-html="item.duration"></p>
      </li>
    </ul>
    <div class="logo logo-white"></div>
    <div
      class="home"
      @click.prevent="onHome"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PageMenu',
  props: {
    config: {
      type: Object,
      required: true,
    },
    phases: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    onClick(action) {
      this.$emit('action', action);
    },
    onHome() {
      this.$emit('action', { type: 'set-page', data: 'PageSplash' });
    },
  },
  mounted() {
    this.items = this.config.items;
  }
}
</script>
