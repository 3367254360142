<template>
  <div class="page video background">
    <video
      :src="video.src"
      :poster="video.poster"
      autoplay
      playsinline
      @ended="onClose"
    ></video>
  </div>
</template>

<script>
export default {
  name: 'PageVideo',
  props: {
    video: {
      type: Object,
      required: true,
    }
  },
  methods: {
    onClose() {
      this.$emit('action', { type: 'close-video', data: { phase: this.video.phase, pass: true, score: 1 } });
    },
  },
}
</script>
