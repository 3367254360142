<template>
  <div class="page splash background-splash">
    <h1 class="font-light font-size-52 line-height-10 fg-white">Welcome to <br><span class="font-stylized font-size-105 line-height-10 mt-5">CLARA’S STORY:</span></h1>
    <h2 class="font-light font-size-52 line-height-13 fg-white">From Symptoms to Diagnosis <br>in Cardiovascular Management</h2>
    <div class="content bg-black">
      <p class="font-size-26 line-height-13">In this unique 4-part educational experience step into the shoes <br>of Clara, who notices unusual symptoms while exercising. <br>Accompany Clara as her cardiologist; review her medical history, <br>conduct tests, and make critical decisions to arrive at the correct <br>diagnosis.</p>
      <p class="font-stylized font-size-52 line-height-13 mt-4">Are you ready to investigate?</p>
      <div class="flex flex-row align-center mt-5">
        <div
          class="button button-prompt font-extrabold font-size-28 line-height-10"
          @click.prevent="onStart"
        >Begin story</div>
        <p class="font-size-19 line-height-17 ml-5">The content of this experience has been co-created by <br>BMS, Dr Alexandros Kasiakogias & Dr Shouvik Haldar. </p>
      </div>
      <p class="font-size-19 line-height-10 mt-6">This non-promotional educational experience has been developed and funded by BMS.</p>
    </div>
    <footer class="bg-black">
      <div class="logo logo-white ml-8"></div>
      <div class="text-center">
        <p class="">Intended for Healthcare Professionals in the UK and Ireland only.</p>
        <p class="mt-2"><strong>Job code:</strong> CV-GB-2300289 &nbsp;|&nbsp; <strong>Date of preparation:</strong> May 2024</p>
      </div>
      <div class="bcs-logo"></div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PageSplash',
  methods: {
    onStart() {
      this.$emit('action', { type: 'track-event', data: {
        type: 'click',
        module: 'splash',
        id: 'start_experience',
      } });
      this.$emit('action', { type: 'set-page', data: 'PageGoals' });
    },
  },
}
</script>
