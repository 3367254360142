<template>
  <div class="page popup background feedback">
    <div class="popup-wrapper">
      <div class="popup-inner">
        <div class="content">
          <h1>Feedback</h1>

          <div v-if="ready" class="questions">
            <div
              v-for="(question, index) in questions"
              :key="index"
              class="flex flex-row align-items-center justify-content-space-between"
            >
              <p v-html="question"></p>
              <ul :class="[ { selected: feedback[index] > 0 } ]">
                <li 
                  v-for="choice in 5"
                  :key="choice"
                  v-html="choice"
                  :class="feedback[index] === choice ? 'selected' : ''"
                  @click.prevent="select(index, choice)"
                ></li>
              </ul>
            </div>
          </div>
          
          <div
            class="button button-prompt font-extrabold font-size-28 line-height-10"
            :class="[ { inactive: feedback[index] < 0 } ]"
            @click.prevent="onSubmit"
          >Submit</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFeedback',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      questions: [],
      feedback: [],
    }
  },
  methods: {
    onSubmit() {
      window.sessionStorage.setItem('CLARA_FEEDBACK', this.feedback);
      this.$emit('action', { type: 'submit-feedback', data: this.feedback });
      this.$emit('action', { type: 'set-page', data: 'PageComplete' });
    },

    select(question, choice) {
      this.feedback[question] = choice;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.questions = this.config.feedback
    this.questions.forEach(() => this.feedback.push(-1));
    this.ready = true;
  },
}
</script>
