<template>
  <div class="page goals background">
    <h1 class="font-extrabold font-size-30 line-height-10 fg-white">Upon completion of this 4-part simulation, participants should be able to:</h1>
    <ul class="content-list bg-black">
      <li>
        <div class="index fg-purple">1</div>
        <p class="font-size-30 line-height-13">Understand the key symptoms that may be associated with this cardiovascular disease.</p>
      </li>
      <li>
        <div class="index fg-purple">2</div>
        <p class="font-size-30 line-height-13">Efficiently and correctly manage a patient presenting with cardiovascular symptoms, including <br>utilisation of the correct diagnostic tests and interpretation of imaging results.</p>
      </li>
      <li>
        <div class="index fg-purple">3</div>
        <p class="font-size-30 line-height-13">Correctly diagnose patients in a range of interactive scenarios.</p>
      </li>
    </ul>
    <div
      class="button button-prompt font-extrabold font-size-28 line-height-10"
      @click.prevent="onStart"
    >Continue</div>
    <div
      class="home"
      @click.prevent="onHome"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PageGoals',
  props: {
    config: {
      type: Object,
      required: true,
    },
    phases: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    onStart() {
      this.$emit('action', { type: 'track-event', data: {
        type: 'click',
        module: 'goals',
        id: 'continue',
      } });
      this.$emit('action', { type: 'set-page', data: 'PageMenu' });
    },
    onHome() {
      this.$emit('action', { type: 'set-page', data: 'PageSplash' });
    },
  },
  mounted() {
    this.items = this.config.items;
  }
}
</script>
